@import url(https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap);
#root {
  height: 100%;
}
:root {
  --block-height-M: 4rem;
  --block-height-S: 3.2rem;
  --font-size-XS: 1rem;
  --font-size-S: 1.2rem;
  --font-size-M: 1.4rem;
  --font-size-L: 1.6rem;
  --font-size-XL: 2rem;
  --color-background: #fff;
  --color-loading-background: rgb(22, 63, 137);
  --color-loading-content: #fff;
  --color-brand: #163f89;
  --color-brand-highlight: #d1dee7;
  --color-brand-secondary: rgba(22, 63, 137, 0.7);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  font-family: var(--font-family);
  font-size: var(--font-size-S);
  line-height: 2rem;
  color: var(--color-foreground);
  fill: var(--color-foreground);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: all 150ms ease-out;
  background-repeat: no-repeat;
  background-position: center center;
}

.loader_loaderContainer__MnTaC{display:flex;text-align:center;align-items:center;justify-content:center;height:100vh;color:var(--color-loading-content);background:url("https://globalfishingwatch.org/wp-content/uploads/map_option-4.jpg");background-size:cover}.loader_placeholder__1Y0vH{text-align:center;margin-top:20px}.loader_spinner__3lm7X{margin:20px;height:30px;width:30px;color:var(--color-brand);position:relative;display:inline-block;border:3px solid;border-radius:50%;border-top-color:transparent;animation:loader_rotate__13zN4 1s linear infinite}.loader_logoImage__3SZ-W{margin-top:10px;display:block;background:rgba(255,255,255,0.7);border-radius:20px;border:1px solid var(--color-loading-background);padding:10px 25px}@keyframes loader_rotate__13zN4{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}

.Login_container__13M3K {
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: var(--main-background-color);
  height: calc(var(--vh, 1vh) * 100);
  background-image: url(/static/media/login-bg.b3d40083.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Login_logoImage__cBpky {
  margin-bottom: var(--space-S);
}

.Login_textContainer__3OfmB {
  max-width: 380px;
  margin-left: 110px;
}

@media only screen and (max-width: 72rem) {
  .Login_textContainer__3OfmB {
    margin: 0 3rem;
  }
}

.Login_textTitle__pyF0z {
  font-size: var(--extra-large-font-size);
  letter-spacing: 1px;
  line-height: 28px;
  color: var(--color-background);
}

.Login_textSubtitle__wBQxf {
  font-size: var(--font-size);
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 30px;
  letter-spacing: 0;
  color: var(--color-background);
}

.Login_link__16ou8,
.Login_link__16ou8:visited {
  padding: 1rem 3rem;
  line-height: 2rem;
  text-transform: uppercase;
  border-radius: 0.3rem;
  background: #e74327;
  color: var(--color-background);
  width: 100%;
}

.Login_copyright__JmNoV {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 1.4rem;
  color: var(--color-background);
  opacity: 0.5;
}

